import { useEffect } from 'react'
import theme from '../theme'

function useChatra () {
  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    if (isBrowser && !window.Chatra && process.env.NODE_ENV === 'production') {
      (function (d, w, c) {
        w.ChatraID = 'dsi5Wh3p6grSPzN8Y'
        const s = d.createElement('script')
        w[c] = w[c] || function () {
          (w[c].q = w[c].q || []).push(arguments)
        }
        s.async = true
        s.src = 'https://call.chatra.io/chatra.js'
        if (d.head) d.head.appendChild(s)
      })(document, window, 'Chatra')
    }
    window.ChatraSetup = {
      colors: {
        buttonText: '#f0f0f0', /* цвет текста кнопки чата */
        buttonBg: theme.colors.primary /* цвет фона кнопки чата */
      }
    }
  }, [])
}

export default useChatra
