import 'sweetalert2/src/sweetalert2.scss'
import Head from 'next/head'
import React, { useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.min.css'
import '../styles/datepicker-chakra.css'
import '../styles/custom.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { init } from '../helpers/sentry'

import {
  ThemeProvider,
  CSSReset,
  ColorModeProvider,
  LightMode
} from '@chakra-ui/core'

import theme from '../theme'
import useChatra from '../hooks/use-chatra'

import { DefaultSeo } from 'next-seo'

init()

// TODO HIDE EVENT LINKS FROM SEARCHED INDEXING

const ONESIGNAL_APP_ID = '08e84850-89da-4116-adf0-7f91f47224d6' // prod
const ONESIGNAL_DEV_APP_ID = '26ef33e7-ad38-42ea-a6a3-904e029c7376' // dev

const ONESIGNAL_SAFARI_DEV_WEB_ID =
  'web.onesignal.auto.0d6d1ede-d24a-45d0-ba73-2f88839c0735'
const ONESIGNAL_SAFARI_WEB_ID =
  'web.onesignal.auto.50aab334-fdef-4f2b-a12a-95c1e616813d'
const isDev = process.env.NODE_ENV === 'development'

const meta = {
  title: 'Hapen',
  description:
            'Create beautiful event pages with built-in reminders. Expand your audience allowing them choose the preffered method of receiving notifications about your event.'
}

function MyApp ({ Component, pageProps, err }) {
  useEffect(() => {
    if (process.browser) {
    // Google ANALytics
      if (process.env.NODE_ENV === 'production') {
        window.dataLayer = window.dataLayer || []
        // eslint-disable-next-line
        function gtag() {
          dataLayer.push(arguments)
        }
        gtag('js', new Date())

        gtag('config', 'G-LQ06FJNM29')
      }
      // OneSignal
      window.OneSignal = window.OneSignal || []
      if (process.env.NODE_ENV === 'production') {
        window.OneSignal.push(function () {
          window.OneSignal.init({
            appId: isDev ? ONESIGNAL_DEV_APP_ID : ONESIGNAL_APP_ID,
            allowLocalhostAsSecureOrigin: isDev,
            notifyButton: {
              enable: false
            },
            welcomeNotification: {
              disable: true
            },
            safari_web_id: isDev
              ? ONESIGNAL_SAFARI_DEV_WEB_ID
              : ONESIGNAL_SAFARI_WEB_ID
          })
        })
      }
    }
  }, [process.browser])

  return (
    <>
      <Head>
        <script src='https://cdn.onesignal.com/sdks/OneSignalSDK.js' async='' />
        <link
          rel='stylesheet'
          type='text/css'
          href='https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.css'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/static/icon-180x180.png'
        />
        <link rel='manifest' href='/manifest.webmanifest' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0,
     user-scalable=0'
        />
        {/* Yandex Counter */}
        {process.env.NODE_ENV === 'production' && (
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
            m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
            (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
         
            ym(68515072, "init", {
                 clickmap:true,
                 trackLinks:true,
                 accurateTrackBounce:true,
                 webvisor:true
            });
       `
            }}
          />
        )}
        {/* Google Analytics */}
        {process.env.NODE_ENV === 'production' && (
          <script
            async
            src='https://www.googletagmanager.com/gtag/js?id=G-LQ06FJNM29'
          />
        )}
      </Head>
      <DefaultSeo
        title='Hapen'
        description={meta.description}
        openGraph={{
          title: meta.title,
          description: meta.description,
          images: [{
            url: 'https://hapen.io/static/main-page-og.png'
          }]
        }}
        twitter={{
          cardType: 'summary_large_image',
          site: '@HapenApp',
          handle: '@HapenApp'
        }}
        facebook
      />
      <ThemeProvider theme={theme}>
        <ColorModeProvider>
          <LightMode>
            <CSSReset />
            <Component err={err} {...pageProps} />
          </LightMode>
        </ColorModeProvider>
      </ThemeProvider>
      <script
        src='https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js'
        data-cfasync='false'
      />
      <style>
        {`
          .cc-message {
            flex: initial !important;
            max-width: initial !important;
          }
          `}
      </style>
    </>
  )
}

export default MyApp
