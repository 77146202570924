import { theme as chakraTheme } from '@chakra-ui/core'

const fonts = { ...chakraTheme.fonts, mono: "'Menlo', monospace" }

const breakpoints = ['40em', '52em', '64em']

const theme = {
  ...chakraTheme,
  colors: {
    ...chakraTheme.colors,
    primary: '#4f6ff2',
    black: '#101010',
    purpleBlue: '#4065FF',
    cardBlue: '#4065FF',
    grey: '#7E96A6',
    blackGrey: '#707070',
    strongBlack: '#1F262E',
    wayBlue: '#4065FF',
    danger: 'linear-gradient(90deg, #f14d56 0%, #e63e3e 100.03%)'
  },
  fonts,
  breakpoints,
  icons: {
    ...chakraTheme.icons,
    logo: {
      path: (
        <svg
          width='3000'
          height='3163'
          viewBox='0 0 3000 3163'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='3000' height='3162.95' fill='none' />
          <path
            d='M1470.89 1448.81L2170 2488.19H820V706.392H2170L1470.89 1448.81ZM1408.21 1515.37L909.196 2045.3V2393.46H1998.84L1408.21 1515.37Z'
            fill='currentColor'
          />
        </svg>
      ),
      viewBox: '0 0 3000 3163'
    }
  }
}

export default theme
